import React, { Component } from "react";

class Api extends Component {
  functionTest = async () => {
    const botToken = process.env.REACT_APP_URL_FETCH;
    // const botToken = process.env.REACT_APP_URL_FETCH_LOCAL;
    try {
      const response = await fetch(`${botToken}`);
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Ошибка запроса:", err);
    }
  };
}

export default Api;
