import React, { useRef } from "react";
import YouTube from "react-youtube";

const VideoComp = ({ videoUrl }) => {
  const playerRef = useRef(null);

  const getVideoId = (url) => {
    // Извлекаем идентификатор видео из ссылки
    const match = url.match(/(?:youtu\.be\/|v=)([^&?/]+)/);
    return match ? match[1] : null;
  };

  const videoId = getVideoId(videoUrl);

  const opts = {
    height: "656px",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  const handleReady = (event) => {
    playerRef.current = event.target;
    event.target.pauseVideo();
  };

  return videoId ? (
    <YouTube videoId={videoId} opts={opts} onReady={handleReady} />
  ) : (
    <p>Неверная ссылка на видео</p>
  );
};

export default VideoComp;
