import { useMediaQuery } from "react-responsive";
import briefcase from "../assets/icons/briefcase-solid.svg";
import commentsRegl from "../assets/icons/comments-regular.svg";
import sackDollar from "../assets/icons/sack-dollar-solid.svg";
import fileSolid from "../assets/icons/file-solid.svg";
import cashRegister from "../assets/icons/cash-register-solid.svg";
import camera from "../assets/icons/camera-solid.svg";
import rankingStart from "../assets/icons/ranking-star-solid.svg";
import personWalkingLuggage from "../assets/icons/person-walking-luggage-solid.svg";
import houseChimney from "../assets/icons/house-chimney-solid.svg";
import scaleImage from "../assets/icons/scale-balanced-solid.svg";
import SmallForm from "../components/SmallForm";
const DlyaVlasnykivNerukhomosti = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const typeOffrom = "Форма: власник";
  return (
    <>
      {isDesktop && (
        <div className="dlya-vlasn-desktop">
          <div className="dlya-vlasn-desktop-section">
            <div className="block-right">
              <h2>Послуги для власників нерухомості в Києві</h2>
              <h3>
                Продамо / здамо вашу нерухомість в потрібні терміни з вашою
                мінімальною участю
              </h3>
              <section className="section-fourth">
                <div className="container">
                  <div className="container-blocks">
                    <img src={fileSolid} alt="" />
                    <img src={sackDollar} alt="" />
                    <img src={commentsRegl} alt="" />
                    <img src={briefcase} alt="" />
                  </div>
                  <div className="line">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                  <div className="container-texts">
                    <p>Інформаційна допомога</p>
                    <p>Оцінка нерухомості</p>
                    <p>Допомога з орендою чи продажем</p>
                    <p>Управління нерухомістю</p>
                  </div>
                </div>
                <div className="section-fourth-button"></div>
              </section>
            </div>
            <SmallForm
              formTitleContent={
                <div>
                  <h2>Бажаєте, щоб ми продали або здали ваш об'єкт?</h2>
                  <p>Залишіть заявку.</p>
                </div>
              }
              addInf={typeOffrom}
            ></SmallForm>
          </div>
          <div className="dlya-vlasn-desktop-bottom">
            <ul>
              <li>
                <img src={cashRegister} alt="" />
                <h3>Встановимо оптимальну стартову ціну</h3>
                <p>
                  Проведемо детальний аналіз ринку, щоб обґрунтовано визначити
                  вартість вашого об'єкта, що допоможе швидше його продати або
                  здати в оренду.
                </p>
              </li>
              <li>
                <img src={camera} alt="" />
                <h3>Організуємо якісну фото- та відеозйомку</h3>
                <p>
                  Наш професійний фотограф створить вражаючі знімки вашого
                  об'єкта, які максимально привернуть увагу потенційних
                  покупців.
                </p>
              </li>
              <li>
                <img src={rankingStart} alt="" />
                <h3>Ефективно продамо вашу нерухомість</h3>
                <p>
                  Визначимо вашу цільову аудиторію, створимо продаючий опис
                  об'єкта та організуємо рекламну кампанію з використанням
                  сучасних digital-інструментів.
                </p>
              </li>
              <li>
                <img src={personWalkingLuggage} alt="" />
                <h3>Залучимо лише зацікавлених покупців</h3>
                <p>
                  Наша мета – знайти покупця або орендаря для вашого об'єкта, а
                  не просто організовувати покази. Ми зосереджені на якості, а
                  не на кількості.
                </p>
              </li>
              <li>
                <img src={houseChimney} alt="" />
                <h3>Ведемо переговори та захищаємо ваші інтереси</h3>
                <p>
                  Ми експерти в переговорах, які є ключовим етапом угоди. Про
                  нашу майстерність свідчить 99% успішно завершених угод.
                </p>
              </li>
              <li>
                <img src={scaleImage} alt="" />
                <h3>Забезпечимо допомогу у сфері нерухомості</h3>
                <p>
                  Наш працівник надасть домогу та рекомендації щодо підготовки
                  документів, щоб Ваша угода була безпечною та бездоганною.
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="dlya-vlasn-mobile">
          {" "}
          <div className="dlya-vlasn-desktop-section">
            <div className="block-right">
              <h2>Послуги для власників нерухомості в Києві</h2>
              <h3>
                Продамо / здамо вашу нерухомість в потрібні терміни з вашою
                мінімальною участю
              </h3>
              <section className="section-fourth">
                <div className="container">
                  <div className="container-blocks">
                    <img src={fileSolid} alt="" />
                    <img src={sackDollar} alt="" />
                    <img src={commentsRegl} alt="" />
                    <img src={briefcase} alt="" />
                  </div>
                  <div className="line">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                  <div className="container-texts">
                    <p>Інформаційна допомога</p>
                    <p>Оцінка нерухомості</p>
                    <p>Допомога з орендою чи продажем</p>
                    <p>Управління нерухомістю</p>
                  </div>
                </div>
                <div className="section-fourth-button"></div>
              </section>
            </div>
            <SmallForm
              formTitleContent={
                <div>
                  <h2>Бажаєте, щоб ми продали або здали ваш об'єкт ?</h2>
                  <p>Залишіть заявку.</p>
                </div>
              }
              addInf={typeOffrom}
            ></SmallForm>
          </div>
          <div className="dlya-vlasn-desktop-bottom">
            <ul>
              <li>
                <img src={cashRegister} alt="" />
                <h3>Встановимо оптимальну стартову ціну</h3>
                <p>
                  Проведемо детальний аналіз ринку, щоб обґрунтовано визначити
                  вартість вашого об'єкта, що допоможе швидше його продати або
                  здати в оренду.
                </p>
              </li>
              <li>
                <img src={camera} alt="" />
                <h3>Організуємо якісну фото- та відеозйомку</h3>
                <p>
                  Наш професійний фотограф створить вражаючі знімки вашого
                  об'єкта, які максимально привернуть увагу потенційних
                  покупців.
                </p>
              </li>
              <li>
                <img src={rankingStart} alt="" />
                <h3>Ефективно продамо вашу нерухомість</h3>
                <p>
                  Визначимо вашу цільову аудиторію, створимо продаючий опис
                  об'єкта та організуємо рекламну кампанію з використанням
                  сучасних digital-інструментів.
                </p>
              </li>
              <li>
                <img src={personWalkingLuggage} alt="" />
                <h3>Залучимо лише зацікавлених покупців</h3>
                <p>
                  Наша мета – знайти покупця або орендаря для вашого об'єкта, а
                  не просто організовувати покази. Ми зосереджені на якості, а
                  не на кількості.
                </p>
              </li>
              <li>
                <img src={houseChimney} alt="" />
                <h3>Ведемо переговори та захищаємо ваші інтереси</h3>
                <p>
                  Ми експерти в переговорах, які є ключовим етапом угоди. Про
                  нашу майстерність свідчить 99% успішно завершених угод.
                </p>
              </li>
              <li>
                <img src={scaleImage} alt="" />
                <h3>Забезпечимо допомогу у сфері нерухомості</h3>
                <p>
                  Наш працівник надасть домогу та рекомендації щодо підготовки
                  документів, щоб Ваша угода була безпечною та бездоганною.
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default DlyaVlasnykivNerukhomosti;
