import React, { useState, useEffect } from "react";
import Api from "./services/api";
import { Link, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Catalog from "./pages/Catalog";
import BigObject from "./pages/BigObject";
import DlyaVlasnykivNerukhomosti from "./pages/DlyaVlasnykivNerukhomosti";
import { useMediaQuery } from "react-responsive";
import PolicityAndPrivaty from "./pages/PolicityAndPrivaty";
import { ModalProvider } from "./components/ModalContext";
function App() {
  const api = new Api();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [isCookie, setIsCookie] = useState(true);
  const [parentIdObject, setParentIdObject] = useState("");
  const [arrayEstates, setArrayEstates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateParentData = (newData) => {
    setParentIdObject(newData);
  };
  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (cookieConsent === "true") {
      setIsCookie(false);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.functionTest();

        setArrayEstates(data.items || []);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const hadnleCookieClick = () => {
    setIsCookie(false);
    localStorage.setItem("cookieConsent", "true");
  };
  return (
    <ModalProvider>
      <div className="App">
        <Header />
        <div
          id={isDesktop ? "cookie_popup" : "cookie_popupM"}
          style={{ display: `${isCookie ? "flex" : "none"}` }}
        >
          <p>
            Ми використовуємо cookie-файли для надання найбільш актуальної
            інформації. Продовжуючи використовувати сайт, Ви погоджуєтесь з
            використанням файлів{"  "}
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              cookie
            </a>{" "}
            та{" "}
            <Link to={"/policity-and-privaty"}>
              Політикою конфіденційності та приватності
            </Link>{" "}
            .
          </p>
          <button onClick={() => hadnleCookieClick()}>Прийняти</button>
        </div>
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <Home arrayEstates={arrayEstates} isLoadingMain={isLoading} />
              }
            />
            <Route
              path="/catalog"
              element={<Catalog arrayEstates={arrayEstates} />}
            />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route
              path="/estate/:id"
              element={<BigObject arrayEstates={arrayEstates} />}
            />
            <Route path="*" />
            <Route
              path="/policity-and-privaty"
              element={<PolicityAndPrivaty />}
            />
            <Route path="*" />
            <Route
              path="/dlya-vlasn-neruhomosti"
              element={<DlyaVlasnykivNerukhomosti />}
            />
            <Route path="*" />
          </Routes>
        </main>

        <Footer />
      </div>
    </ModalProvider>
  );
}

export default App;
