import { useState, useRef, useEffect } from "react";
import Tune from "../assets/icons/Tune.svg";
import { useMediaQuery } from "react-responsive";
import Vector13 from "../assets/icons/Vector13.svg";
import Vector134 from "../assets/icons/Vector134.svg";
const FiltersSection = ({
  upDateFilters,
  filters,
  setCurrentPage,
  countOfSorteredItems,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [isMobileModal, setIsMobileModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState("City");
  const [activeFilterRentOrBuy, setActiveFilterRentOrBuy] = useState();
  const [isModalActive, setIsModalActive] = useState(false);
  const [city, setCity] = useState("Київ");
  const [borough, setBorough] = useState("Весь Київ");
  const [type, setType] = useState("");
  const [typeOfSort, setTypeOfSort] = useState("default");
  const [values, setValues] = useState([1, 100]);
  const [countOfRooms, setCountOfRooms] = useState([1]);
  const [countOfFloors, setCountOfFloors] = useState([1]);
  const [isCheckedGas, setIsCheckedGas] = useState(false);
  const [isCheckedAnimals, setIsCheckedAnimals] = useState(false);
  const [isCheckedChildren, setIsCheckedChildren] = useState(false);
  const modalRef = useRef(null);
  const buttonRef = useRef(null); // Ссылка на кнопку открытия модального окна
  const handlISMobileModal = () => {
    setIsMobileModal(!isMobileModal);
  };
  // Обработчики изменений
  const handleCityChange = (newCity) => {
    setCity(newCity);
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      city: newCity,
    }));
  };
  const handleTypeChangeSquareFrom = (event) => {
    const value = Number(event.target.value); // Преобразуем значение из строки в число
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      squareFrom: value, // Обновляем только squareFrom
    }));
  };
  // Обновляем оба значения площади: min и max
  const handleTypeChangeSquareTo = (event) => {
    const value = Number(event.target.value); // Преобразуем значение из строки в число

    upDateFilters((prevFilters) => ({
      ...prevFilters,
      squareTo: value, // Минимальная площадь
    }));
  };
  const handleRoomSelection = (room) => {
    upDateFilters((prevFilters) => {
      const selectedRooms = [...prevFilters.selectedRooms];
      if (selectedRooms.includes(room)) {
        // Если комната уже выбрана, убираем её
        return {
          ...prevFilters,
          selectedRooms: selectedRooms.filter((r) => r !== room),
        };
      } else {
        // Иначе добавляем её в массив
        return {
          ...prevFilters,
          selectedRooms: [...selectedRooms, room],
        };
      }
    });
  };
  const handleTypeChangeFloorsFrom = (event) => {
    const value = Number(event.target.value); // Преобразуем значение из строки в число
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      floorFrom: value, // Обновляем только squareFrom
    }));
  };
  // Обновляем оба значения площади: min и max
  const handleTypeChangeFloorsTo = (event) => {
    const value = Number(event.target.value); // Преобразуем значение из строки в число

    upDateFilters((prevFilters) => ({
      ...prevFilters,
      floorTo: value, // Минимальная площадь
    }));
  };
  const handleTypeChangePriceFrom = (event) => {
    const value = Number(event.target.value); // Преобразуем значение из строки в число
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      priceFrom: value, // Обновляем только squareFrom
    }));
  };
  // Обновляем оба значения площади: min и max
  const handleTypeChangePriceTo = (event) => {
    const value = Number(event.target.value); // Преобразуем значение из строки в число

    upDateFilters((prevFilters) => ({
      ...prevFilters,
      priceTo: value, // Минимальная площадь
    }));
  };
  const handleCityChangeBorough = (boroughNew) => {
    setBorough(boroughNew);
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      borough: boroughNew,
    }));
  };

  const handleTypeOfSortChange = (event) => {
    const newtypeOfSort = event.target.value;
    setTypeOfSort(newtypeOfSort);
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      typeOfSort: newtypeOfSort,
    }));
  };

  const handleBoroughChange = (event) => {
    const newBorough = event.target.value;
    setBorough(newBorough);
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      borough: newBorough,
    }));
  };

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    setType(newType);
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      type: newType,
    }));
  };

  const handleRentOrBuyChange = (filter) => {
    setActiveFilterRentOrBuy(filter);
    upDateFilters((prevFilters) => ({
      ...prevFilters,
      deal: filter,
    }));
  };
  // Обработчик изменения состояния чекбокса
  const handleCheckboxChange = (type) => {
    switch (type) {
      case "gas":
        setIsCheckedGas(!isCheckedGas);
        upDateFilters((prevFilters) => ({
          ...prevFilters,
          isGaz: !isCheckedGas,
        }));
        break;
      case "animals":
        setIsCheckedAnimals(!isCheckedAnimals);
        upDateFilters((prevFilters) => ({
          ...prevFilters,
          canAnimals: !isCheckedAnimals,
        }));
        break;
      case "children":
        setIsCheckedChildren(!isCheckedChildren);
        upDateFilters((prevFilters) => ({
          ...prevFilters,
          letChildren: !isCheckedChildren,
        }));
        break;
      default:
        break;
    }
  };
  const openModalWindow = () => {
    setIsModalActive(true);
  };

  const closeModalWindow = () => {
    setIsModalActive(false);
  };
  const resetFilters = () => {
    setCity("Київ");
    setType("");
    handleRentOrBuyChange("усі");
    setActiveFilter("City");
    setTypeOfSort("default");
    upDateFilters({
      city: "Київ",
      borough: "Весь Київ",
      type: "усі",
      deal: "усі",
      typeOfSort: "default",
      squareFrom: 0,
      squareTo: 0,
      selectedRooms: [],
      floorFrom: 0,
      floorTo: 0,
      isGaz: false,
      canAnimals: false,
      letChildren: false,
      priceFrom: 0,
      priceTo: 0,
    });
  };
  // Добавляем обработчик кликов по документу, чтобы закрывать окно при клике вне его
  useEffect(() => {
    if (isModalActive) {
      document.addEventListener("click", handleModalClick);
    }

    // Убираем обработчик при размонтировании компонента
    return () => {
      document.removeEventListener("click", handleModalClick);
    };
  }, [isModalActive]);

  const handleModalClick = (e) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      !buttonRef.current.contains(e.target)
    ) {
      closeModalWindow();
    }
  };

  return (
    <div>
      {isDesktop && (
        <div className="filter-section-main">
          <div className="filter-section-main-top">
            <h2>ФІЛЬТРИ</h2>
            <div className="sort-section">
              <p>Сортувати:</p>
              <div className="custom-select">
                <select value={typeOfSort} onChange={handleTypeOfSortChange}>
                  <option value="default">За замовченням</option>
                  <option value="low-to-high">Від дешевих до дорогих</option>
                  <option value="high-to-low">Від дорогих до дешевих</option>
                  <option value="date-of-actul">Даті додавання</option>
                  {/* <option value="from-old-to-new">Від нових до старих</option> */}
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
          </div>
          <div id="filter-section">
            <div className="filter-section-left section-with-button">
              <button
                className={activeFilter === "City" ? "active-light " : ""}
                onClick={() => {
                  setActiveFilter("City");
                  handleCityChange("Київ"); // Устанавливаем город "Київ"
                  handleCityChangeBorough("Весь Київ");
                }}
              >
                Київ
              </button>
              <button
                className={activeFilter === "Region" ? "active-light" : ""}
                onClick={() => {
                  setActiveFilter("Region");
                  handleCityChange("Київська область"); // Устанавливаем регион "Київська область"
                  handleCityChangeBorough("Усі міста");
                }}
              >
                Київська область
              </button>
            </div>

            <div className="filter-section-middle">
              {activeFilter === "City" ? (
                <div className="custom-select city">
                  <select
                    value={filters.borough}
                    onChange={handleBoroughChange}
                  >
                    <option value="Весь Київ" disabled hidden>
                      Оберіть район
                    </option>
                    <option value="Весь Київ">Весь Київ</option>
                    <option value="Голосіївський район">
                      Голосіївський район
                    </option>
                    <option value="Дарницький район">Дарницький район</option>
                    <option value="Деснянський район">Деснянський район</option>
                    <option value="Дніпровський район">
                      Дніпровський район
                    </option>
                    <option value="Оболонський район">Оболонський район</option>
                    <option value="Печерський район">Печерський район</option>
                    <option value="Подільський район">Подільський район</option>
                    <option value="Святошинський район">
                      Святошинський район
                    </option>
                    <option value="Солом'янський район">
                      Солом'янський район
                    </option>
                    <option value="Шевченківський район">
                      Шевченківський район
                    </option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
              ) : (
                <div className="custom-select region">
                  <select value={borough} onChange={handleBoroughChange}>
                    <option value="Усі міста" selected>
                      Усі міста
                    </option>
                    {/* <option value="Біла Церква">Біла Церква</option>
                    <option value="Чорнобиль">Чорнобиль</option> */}
                  </select>
                  <div className="select-arrow"></div>
                </div>
              )}
            </div>

            <div className="filter-section-middle-right section-with-button">
              <button
                className={
                  activeFilterRentOrBuy === "Оренда" ? "active-light" : ""
                }
                onClick={() => {
                  setCurrentPage(1);
                  handleRentOrBuyChange("Оренда");
                }}
              >
                Оренда
              </button>
              <button
                className={
                  activeFilterRentOrBuy === "Продаж" ? "active-light" : ""
                }
                onClick={() => {
                  handleRentOrBuyChange("Продаж");
                  setCurrentPage(1);
                }}
              >
                Продаж
              </button>
            </div>

            <div className="filter-section-left-start">
              <div className="custom-select">
                <select value={type || "усі"} onChange={handleTypeChange}>
                  <option value="усі" hidden disabled>
                    Тип нерохумості
                  </option>
                  <option value="усі">Усі варіанти</option>
                  <option value="Квартира">Квартира</option>
                  <option value="Будинок">Будинок</option>
                  <option value="Офіс">Офіс</option>
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>

            <div className="filter-section-left-end">
              <button ref={buttonRef} onClick={openModalWindow}>
                Усі фільтри <img src={Tune} alt="" />
              </button>
            </div>
          </div>
          {isModalActive && (
            <div ref={modalRef} id="modal-window" className="modal-window">
              <div className="modal-content">
                <div className="modal-content-square">
                  <p>Площа: </p>
                  <input
                    type="number"
                    onwheel="this.blur()"
                    placeholder="Від"
                    onChange={handleTypeChangeSquareFrom}
                  />
                  <input
                    type="number"
                    placeholder="До"
                    onChange={handleTypeChangeSquareTo}
                  />
                </div>
                <div className="count-of-rooms">
                  <p>Кількість кімнат:</p>
                  {[1, 2, 3, 4, 5].map((room) => (
                    <label key={room}>
                      <input
                        type="checkbox"
                        checked={filters.selectedRooms.includes(room)}
                        onChange={() => handleRoomSelection(room)}
                      />
                      {room === 5 ? "5+" : room}
                    </label>
                  ))}
                </div>
                <div className="count-of-floors">
                  <p>Поверх:</p>
                  <input
                    type="number"
                    placeholder="Від"
                    onChange={handleTypeChangeFloorsFrom}
                  />
                  <input
                    type="number"
                    placeholder="До"
                    onChange={handleTypeChangeFloorsTo}
                  />
                </div>
                <div className="count-of-price">
                  <p>Ціна: </p>
                  <input
                    type="number"
                    placeholder="Від"
                    onChange={handleTypeChangePriceFrom}
                    step="1"
                  />
                  <input
                    type="number"
                    placeholder="До"
                    onChange={handleTypeChangePriceTo}
                    step="1"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="filter-section-bottom">
            <button onClick={() => resetFilters()}>СКИНУТИ ФІЛЬТРИ</button>
            <p>Результати ({countOfSorteredItems})</p>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="filter-section-main-mobile">
          <div className="filter-section-main-top">
            <h2 style={{ display: "flex" }} onClick={handlISMobileModal}>
              <p className="big-text-variable">ФІЛЬТРИ</p>{" "}
              <img src={Vector13} alt="" style={{ marginLeft: "10px" }} />
            </h2>
            <div className="sort-section">
              <p className="small-text-variable">Сортувати:</p>
              <div className="custom-select">
                <select value={typeOfSort} onChange={handleTypeOfSortChange}>
                  <option value="default">За замовченням</option>
                  <option value="low-to-high">Від дешевих до дорогих</option>
                  <option value="high-to-low">Від дорогих до дешевих</option>
                  <option value="date-of-actul">Даті актуалізації</option>
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
          </div>
          <div
            className="filter-section"
            style={{ display: `${isMobileModal ? "block" : "none"}` }}
          >
            <div className="filter-section-left">
              <button
                className={
                  activeFilter === "City"
                    ? "active-light"
                    : "small-text-variable"
                }
                onClick={() => {
                  setActiveFilter("City");
                  handleCityChange("Київ"); // Устанавливаем город "Київ"
                  handleCityChangeBorough("Весь Київ");
                }}
              >
                Київ
              </button>
              <button
                className={
                  activeFilter === "Region"
                    ? "active-light"
                    : "small-text-variable"
                }
                onClick={() => {
                  setActiveFilter("Region");
                  handleCityChange("Київська область"); // Устанавливаем регион "Київська область"
                  handleCityChangeBorough("Усі міста");
                }}
              >
                Київська область
              </button>
            </div>

            <div className="filter-section-middle">
              {activeFilter === "City" ? (
                <div className="custom-select city">
                  <select
                    value={filters.borough}
                    onChange={handleBoroughChange}
                  >
                    <option value="Весь Київ">Весь Київ</option>
                    <option value="Голосіївський район">
                      Голосіївський район
                    </option>
                    <option value="Дарницький район">Дарницький район</option>
                    <option value="Деснянський район">Деснянський район</option>
                    <option value="Дніпровський район">
                      Дніпровський район
                    </option>
                    <option value="Оболонський район">Оболонський район</option>
                    <option value="Печерський район">Печерський район</option>
                    <option value="Подільський район">Подільський район</option>
                    <option value="Святошинський район">
                      Святошинський район
                    </option>
                    <option value="Солом'янський район">
                      Солом'янський район
                    </option>
                    <option value="Шевченківський район">
                      Шевченківський район
                    </option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
              ) : (
                <div className="custom-select region">
                  <select value={borough} onChange={handleBoroughChange}>
                    <option value="Усі міста" selected>
                      Усі міста
                    </option>
                    {/* <option value="Біла Церква">Біла Церква</option>
                    <option value="Чорнобиль">Чорнобиль</option> */}
                  </select>
                  <div className="select-arrow"></div>
                </div>
              )}
            </div>

            <div className="filter-section-middle-right">
              <button
                className={
                  activeFilterRentOrBuy === "Оренда" ? "active-light" : ""
                }
                onClick={() => handleRentOrBuyChange("Оренда")}
              >
                Оренда
              </button>
              <button
                className={
                  activeFilterRentOrBuy === "Продаж" ? "active-light" : ""
                }
                onClick={() => handleRentOrBuyChange("Продаж")}
              >
                Продаж
              </button>
            </div>

            <div className="filter-section-left-start">
              <div className="custom-select">
                <select value={type || ""} onChange={handleTypeChange}>
                  <option value="" disabled>
                    Тип нерохумості
                  </option>
                  <option value="Квартира">Квартира</option>
                  <option value="Будинок">Будинок</option>
                  <option value="Офіс">Офіс</option>
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
            <div className="modal-content-square">
              <p>Площа: </p>
              <input
                type="number"
                onwheel="this.blur()"
                placeholder="Від"
                onChange={handleTypeChangeSquareFrom}
              />
              <input
                type="number"
                placeholder="До"
                onChange={handleTypeChangeSquareTo}
              />
            </div>
            <div className="count-of-rooms">
              <p>Кількість кімнат:</p>
              {[1, 2, 3, 4, 5].map((room) => (
                <label key={room}>
                  <input
                    type="checkbox"
                    checked={filters.selectedRooms.includes(room)}
                    onChange={() => handleRoomSelection(room)}
                  />
                  {room === 5 ? "5+" : room}
                </label>
              ))}
            </div>
            <div className="count-of-floors">
              <p>Поверх:</p>
              <input
                type="number"
                placeholder="Від"
                onChange={handleTypeChangeFloorsFrom}
              />
              <input
                type="number"
                placeholder="До"
                onChange={handleTypeChangeFloorsTo}
              />
            </div>
            <div className="count-of-price">
              <p>Ціна: </p>
              <input
                type="number"
                placeholder="Від"
                onChange={handleTypeChangePriceFrom}
                step="1"
              />
              <input
                type="number"
                placeholder="До"
                onChange={handleTypeChangePriceTo}
                step="1"
              />
            </div>
            <button
              className="filter-close-button"
              onClick={handlISMobileModal}
            >
              ЗАКРИТИ ФІЛЬТРИ
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FiltersSection;
