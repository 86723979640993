import LocationImage from "../assets/icons/Location.svg";
import BedImage from "../assets/icons/Bed.svg";
import CityBlock from "../assets/icons/City Block.svg";
import Frame20Image from "../assets/icons/Frame 20.svg";
import { useNavigate } from "react-router-dom";
import fireSolid from "../assets/icons/fire-solid.svg";
const ItemSmall = (elem) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/estate/${elem.elem.article}`);
  };

  const imageUrl = elem.elem.images.image_url[0];

  const location =
    elem.elem.location && elem.elem.location ? elem.elem.location : null;
  const street = location.street._;
  const houseNum = location.house_num;
  const district = location.district?._ || "Unknown";
  const borough = location?._;
  const deal = elem.elem.deal._;
  const dataOfAct = elem.elem.properties.property_102.value;
  const newbuildingName = elem.elem.newbuilding_name;

  const price = elem.elem.price._;
  const priceCurrency = elem.elem.price.currency;

  const roomCount = elem.elem.room_count;
  const areaTotal = elem.elem.area_total;
  const floor = elem.elem.floor;
  const totalFloors = elem.elem.total_floors;
  const exclusive = elem?.elem?.tags?.tag?.includes("exclusive") ?? false;
  const hotPrice = elem?.elem?.tags?.tag?.includes("hotPrice") ?? false;

  return (
    <div className="object" onClick={handleClick}>
      {/* {exclusive ? <div id="exclusive">exclusive</div> : null} */}
      {imageUrl ? <img src={imageUrl} alt="" className="front-image" /> : null}
      <div className="object-decrt">
        <div className="elements">
          <div className="elements-top">
            {deal && (
              <div className="elements-top-deal">
                <p>{deal}</p>
              </div>
            )}
            {dataOfAct && (
              <div className="data-of-actualize">
                <p className="small-text-variable">Дата актуалізації:</p>{" "}
                <p>{dataOfAct}</p>
              </div>
            )}
          </div>
          {street && houseNum && (
            <div className="name">
              ВУЛ. {street} {houseNum}
            </div>
          )}
          {district && borough && (
            <div className="location">
              <img src={LocationImage} alt="" />
              {district}, {borough}
            </div>
          )}
          {newbuildingName ? (
            <div className="borough">
              <div>ЖК:</div> <div>{newbuildingName.slice(2)}</div>
            </div>
          ) : null}
          {price && (
            <div className="price">
              <p>Ціна: </p>
              <p className="price-count">
                {price} {priceCurrency}{" "}
                {/* {hotPrice && (
                  <div id="hotPrice">
                    <img src={fireSolid} alt="" />
                    <img src={fireSolid} alt="" />
                    <img src={fireSolid} alt="" />
                  </div>
                )} */}
              </p>
            </div>
          )}
        </div>
        {/* <div>{elem.elem.tags.tag}</div> */}
        <div className="additional">
          {roomCount && (
            <div>
              <img src={BedImage} alt="" />
              {roomCount}x
            </div>
          )}
          {areaTotal && (
            <div>
              <img src={Frame20Image} alt="" />
              {areaTotal} m²
            </div>
          )}
          {floor && (
            <div>
              <img src={CityBlock} alt="" />
              {floor}/{totalFloors}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemSmall;
