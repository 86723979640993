import { useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import frame63 from "../assets/icons/Frame 63.svg";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe"; // Импортируем сам PhotoSwipe
import "photoswipe/style.css";
import rectangle4 from "../assets/images/Rectangle 4.svg";
import LocationImage from "../assets/icons/Location.svg";
import BedImage from "../assets/icons/Bed.svg";
import CityBlock from "../assets/icons/City Block.svg";
import Frame20Image from "../assets/icons/Frame 20.svg";
import OSMap from "../components/OSMap";
import BottomSections from "../components/BottomSections";
import { useModal } from "../components/ModalContext";
import { useMediaQuery } from "react-responsive";
import SmallForm from "../components/SmallForm";
import VideoComp from "../components/VideoComp";
import { Helmet } from "react-helmet";
const BigObject = ({ arrayEstates }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const typeOffrom = "Форма: не определився";
  // ! ACHTUNG изменяет
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const __scrollConstanta = 1290;
  const [currentObject, setCurrentObject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mainImage, setMainImage] = useState(null);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const { openModal } = useModal();

  const { id } = useParams();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (arrayEstates) {
      const object = arrayEstates.find((estate) => estate.article === id);
      setCurrentObject(object);
      console.log(object);

      setIsLoading(false);
    }
  }, [id, arrayEstates]);
  const images = useMemo(() => {
    return currentObject?.images?.image_url || [];
  }, [currentObject]);
  useEffect(() => {
    setMainImage(images[0]);
  }, [images]);
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#mainImage",
      children: "a",
      pswpModule: PhotoSwipe,
    });
    lightbox.init();

    // return () => {
    //   lightbox.destroy();
    //   lightbox = null;
    // };
  }, []);
  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      handleNextMobile(); // Прокрутка вправо
    } else if (touchEndX - touchStartX > 50) {
      handlePrev(); // Прокрутка влево
    }
    setTouchStartX(0);
    setTouchEndX(0);
  };
  const handleImageClick = (index) => {
    setMainImage(images[index]);
  };
  const handleNext = () => {
    const currentIndex = images.indexOf(mainImage);
    if (currentIndex < images.length - 1) {
      const nextIndex = currentIndex + 1;
      setMainImage(images[nextIndex]);

      if (nextIndex >= currentStartIndex + 4) {
        setCurrentStartIndex(currentStartIndex + 1);
      }
    }
  };
  const handleNextMobile = () => {
    const currentIndex = images.indexOf(mainImage);
    if (currentIndex < images.length - 1) {
      const nextIndex = currentIndex + 1;
      setMainImage(images[nextIndex]);

      if (nextIndex >= currentStartIndex + 3) {
        setCurrentStartIndex(currentStartIndex + 1);
      }
    }
  };
  const handlePrev = () => {
    const currentIndex = images.indexOf(mainImage);
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setMainImage(images[prevIndex]);

      if (prevIndex < currentStartIndex) {
        setCurrentStartIndex(currentStartIndex - 1);
      }
    }
  };
  // const updatedAt = (currentObject.properties?.[0]?.property ?? [])
  //   .map((elem) => {
  //     if (elem.$.attribute === "property_102") {
  //       return elem._;
  //     }
  //     return null; // Если условие не выполняется, возвращаем null
  //   })
  //   .find((value) => value !== null); // Ищем первый ненулевой элемент
  const getLook = () => {
    return openModal(
      <SmallForm
        addInf={id}
        formTitleContent={
          <div>
            <h2>Запишітся на перегляд</h2>
            <p>
              Наші фахівці зв'яжуться з вами, та одразу підготовлять інформацію
              про поточний об'єкт.
            </p>
          </div>
        }
      />
    );
  };
  // Функция для отображения видео
  const renderMainImage = () => {
    const isVideo = currentObject.properties.property_26.value; // Условие для проверки наличия видео
    if (isVideo) {
      return <VideoComp videoUrl={"https://youtu.be/FhLw2HmZH2M"} />;
    }
    return <img src={mainImage} alt="Main" className="main-image" />;
  };
  if (isLoading) {
    return <h1>Загрузка...</h1>;
  }

  if (!currentObject) {
    return <h1>Объект не найден</h1>;
  }

  return (
    <>
      <Helmet>
        <title>
          ВУЛ. {currentObject.location.street._}{" "}
          {currentObject.location.house_num || "N/A"} | Dealine - Агенція
          нерухомості
        </title>
        <meta
          name="description"
          content="Сторінка нерохумості, зі зручним описом, мапою яка відображе місцезнаходження, зручним переглядом фотографій та декількома зручними формами і можливістью запсу на перегляд"
        />
      </Helmet>
      {isDesktop && (
        <div className="big-object">
          <div className="left-block">
            <div className="top-block">
              <div className="top-block-images" id="mainImage">
                {/* <a
                  href={mainImage}
                  data-pswp-width="1920"
                  data-pswp-height="1080"
                  target="_blank"
                >
                  <img src={mainImage} alt="" />
                </a> */}
                <img src={mainImage} alt="Main" className="main-image" />
              </div>

              <div className="bottom-block">
                <div className="buttons">
                  <img
                    src={frame63}
                    alt=""
                    onClick={handlePrev}
                    disabled={images.indexOf(mainImage) === 0}
                  />

                  <img
                    src={frame63}
                    alt=""
                    onClick={handleNext}
                    disabled={currentStartIndex + 4 >= images.length}
                  />
                </div>
                <div className="thumbnail-container">
                  {images
                    .slice(currentStartIndex, currentStartIndex + 4)
                    .map((url, index) => {
                      const absoluteIndex = currentStartIndex + index;
                      return (
                        <img
                          key={absoluteIndex}
                          src={url}
                          loading="lazy"
                          alt={`Thumbnail ${absoluteIndex}`}
                          className={`thumbnail-image ${
                            url === mainImage ? "active" : ""
                          }`}
                          onClick={() => handleImageClick(absoluteIndex)}
                        />
                      );
                    })}
                  {/* {currentObject.properties.property_26.value && (
                    <div className="video-thumbnail">
                      <VideoComp
                        videoUrl={"https://youtu.be/FhLw2HmZH2M"}
                      ></VideoComp>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="middle-block">
              <div className="middle-block-top-right">
                <p className="middle-block-deal">{currentObject.deal._}</p>
                <p className="small-text-variable middle-block-update-at">
                  Дата актуалізації:{" "}
                  <p className="big-text-variable">
                    {/* {updatedAt} */}
                    {currentObject.properties.property_102.value
                      .slice(0, 13)
                      .replace(/-/g, ".")}
                  </p>
                </p>
              </div>
              <div id="section-with-button">
                <div className="name big-text-variable">
                  ВУЛ. {currentObject.location.street._}{" "}
                  {currentObject.location.house_num || "N/A"}
                </div>
                <button id="button-getlook" onClick={getLook}>
                  ЗАПИСАТИСЯ НА ПЕРЕГЛЯД
                </button>
              </div>
              <div className="price">
                <p className="middle-text-variable">Ціна:</p>
                <p className="big-text-variable">
                  {currentObject.price._}{" "}
                  {currentObject.price.currency || "N/A"}
                </p>
              </div>
              <div className="location small-text-variable">
                <img src={LocationImage} alt="" />
                {currentObject.location.district?._
                  ? `${currentObject.location.district._}, `
                  : ""}
                {currentObject.location.city._}
                {currentObject.location.borough?._ || null}
              </div>
              {currentObject.newbuilding_name ? (
                <div className="borough">
                  <div className="middle-text-variable">ЖК:</div>{" "}
                  <div className="small-text-variable">
                    {" "}
                    {currentObject.newbuilding_name.slice(3) || "N/A"}
                  </div>
                </div>
              ) : null}
              <div className="additional">
                <div>
                  <img src={BedImage} alt="" />
                  <p className="small-text-variable">
                    {currentObject.room_count || "N/A"}x
                  </p>
                </div>
                <div>
                  <img src={Frame20Image} alt="" />
                  <p className="small-text-variable">
                    {currentObject.area_total || "N/A"} m²
                  </p>
                </div>
                <div>
                  <img src={CityBlock} alt="" />
                  <p className="small-text-variable">
                    {currentObject.floor}/{currentObject.total_floors || "N/A"}
                  </p>
                </div>
              </div>
              <div className="properties">
                <div>
                  Площа:{" "}
                  <p className="small-text-variable">
                    {currentObject.area_total} m²
                  </p>
                </div>
                <div>
                  Кількість кімнат:{" "}
                  <p className="small-text-variable">
                    {currentObject.room_count}
                  </p>
                </div>
                <div>
                  Поверх:{" "}
                  <p className="small-text-variable">{currentObject.floor}</p>
                </div>
                {currentObject.properties.property_92 && (
                  <div>
                    <p>{currentObject.properties.property_92.name}</p>:{" "}
                    <p className="small-text-variable">
                      {currentObject.properties.property_92.value}
                    </p>
                  </div>
                )}

                {currentObject.properties.property_99 && (
                  <div>
                    <p>{currentObject.properties.property_99.name}</p>:{" "}
                    <p className="small-text-variable">
                      {currentObject.properties.property_99.value}
                    </p>
                  </div>
                )}
                {currentObject.properties.property_2 && (
                  <div>
                    <p>{currentObject.properties.property_2.name}</p>:{" "}
                    <p className="small-text-variable">
                      {currentObject.properties.property_2.value}
                    </p>
                  </div>
                )}
              </div>
              <div className="description">
                <h3>ДЕТАЛЬНІШЕ ПРО КВАРТИРУ</h3>
                <div className="description-bottom">
                  <img src={rectangle4} alt="" id="rectagle4" />
                  <div className="description-text">
                    {currentObject.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="right-block"
            style={{
              position: "sticky",
              top:
                scrollPosition > __scrollConstanta
                  ? `${__scrollConstanta - 70}px`
                  : "0px", // только если элемент фиксированный, делаем его приклеенным к верху
              // marginTop: scrollPosition < 100 ? "100px" : "0px",
              // paddingTop: scrollPosition > 100 ? "100px" : "0px",
            }}
          >
            <div className="right-block-elements">
              <OSMap coordinate={currentObject}></OSMap>
              <SmallForm addInf={typeOffrom}></SmallForm>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="big-object-mobile">
          <div className="left-block">
            <div className="top-block">
              <div className="top-block-images">
                {/* <a
                  href={mainImage}
                  data-lightbox="image-1"
                  data-title="My caption"
                >
                  <img src={mainImage} className="main-image" alt="" />
                </a> */}

                <img src={mainImage} alt="Main" className="main-image" />
                <div className="buttons">
                  <img
                    src={frame63}
                    alt=""
                    onClick={handlePrev}
                    disabled={images.indexOf(mainImage) === 0}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                  />

                  <img
                    src={frame63}
                    alt=""
                    onClick={handleNext}
                    disabled={currentStartIndex + 3 >= images.length}
                  />
                </div>
              </div>

              <div className="bottom-block">
                <div className="thumbnail-container">
                  {images
                    .slice(currentStartIndex, currentStartIndex + 3)
                    .map((url, index) => {
                      const absoluteIndex = currentStartIndex + index;
                      return (
                        <img
                          key={absoluteIndex}
                          src={url}
                          loading="lazy"
                          alt={`Thumbnail ${absoluteIndex}`}
                          className={`thumbnail-image ${
                            url === mainImage ? "active" : ""
                          }`}
                          onClick={() => handleImageClick(absoluteIndex)}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="right-block">
              <div className="right-block-elements">
                <OSMap coordinate={currentObject}></OSMap>
                <SmallForm addInf={typeOffrom}></SmallForm>
              </div>
            </div>
            <div className="middle-block">
              <div className="middle-block-top-right">
                <p className="middle-block-deal">{currentObject.deal._}</p>
                <p className="small-text-variable middle-block-update-at">
                  Дата актуалізації:{" "}
                  <p className="big-text-variable">
                    {currentObject.properties.property_102.value
                      .slice(0, 13)
                      .replace(/-/g, ".")}
                  </p>
                </p>
              </div>
              <div className="name big-text-variable">
                ВУЛ. {currentObject.location.street._}{" "}
                {currentObject.location.house_num || "N/A"}
              </div>
              <button id="button-getlook" onClick={getLook}>
                ЗАПИСАТИСЯ НА ПЕРЕГЛЯД
              </button>
              <div className="price">
                <p className="middle-text-variable">Ціна:</p>
                <p className="big-text-variable">
                  {currentObject.price._}{" "}
                  {currentObject.price.currency || "N/A"}
                </p>
              </div>
              <div className="location small-text-variable">
                <img src={LocationImage} alt="" />
                {currentObject.location.district?._
                  ? `${currentObject.location.district._}, `
                  : ""}
                {currentObject.location.city._}
                {currentObject.location.borough?._ || null}
              </div>
              {currentObject.newbuilding_name ? (
                <div className="borough">
                  <div className="middle-text-variable">ЖК:</div>{" "}
                  <div className="small-text-variable">
                    {" "}
                    {currentObject.newbuilding_name.slice(3) || "N/A"}
                  </div>
                </div>
              ) : null}
              <div className="additional">
                <div>
                  <img src={BedImage} alt="" />
                  <p className="small-text-variable">
                    {currentObject.room_count || "N/A"}x
                  </p>
                </div>
                <div>
                  <img src={Frame20Image} alt="" />
                  <p className="small-text-variable">
                    {currentObject.area_total || "N/A"} m²
                  </p>
                </div>
                <div>
                  <img src={CityBlock} alt="" />
                  <p className="small-text-variable">
                    {currentObject.floor}/{currentObject.total_floors || "N/A"}
                  </p>
                </div>
              </div>
              <div className="properties">
                {currentObject.area_total && (
                  <div>
                    Площа:{" "}
                    <p className="small-text-variable">
                      {currentObject.area_total} m²
                    </p>
                  </div>
                )}
                {currentObject.room_count && (
                  <div>
                    Кількість кімнат:{" "}
                    <p className="small-text-variable">
                      {currentObject.room_count}
                    </p>
                  </div>
                )}
                {currentObject.floor && (
                  <div>
                    Поверх:{" "}
                    <p className="small-text-variable">{currentObject.floor}</p>
                  </div>
                )}

                {currentObject.properties.property_92 && (
                  <div>
                    <p>{currentObject.properties.property_92.name}</p>:{" "}
                    <p className="small-text-variable">
                      {currentObject.properties.property_92.value}
                    </p>
                  </div>
                )}

                {currentObject.properties.property_99 && (
                  <div>
                    <p>{currentObject.properties.property_99.name}</p>:{" "}
                    <p className="small-text-variable">
                      {currentObject.properties.property_99.value}
                    </p>
                  </div>
                )}
                {currentObject.properties.property_2 && (
                  <div>
                    <p>{currentObject.properties.property_2.name}</p>:{" "}
                    <p className="small-text-variable">
                      {currentObject.properties.property_2.value}
                    </p>
                  </div>
                )}
              </div>
              <div className="description">
                <h3>ДЕТАЛЬНІШЕ ПРО КВАРТИРУ</h3>
                <div className="description-bottom">
                  <img src={rectangle4} alt="" id="rectagle4" />
                  <div className="description-text">
                    {currentObject.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <BottomSections
        sectionFourth={true}
        sectionFive={true}
        sectionReviews={false}
        sectionBottom={true}
        indexOfText="second"
      />
    </>
  );
};

export default BigObject;
